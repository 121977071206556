import { createSlice } from "@reduxjs/toolkit";

const userEnterpriseSlice = createSlice({
    name: 'enterprise',
    initialState: { user: '',enterprise:'',loader:true },
    reducers: {
        setUvalue(state, action) {
            console.log(action)
            state.user = action.payload;
        },
        setEvalue(state,action){
            state.enterprise=action.payload
        },
        loadFalse(state){
            state.loader =false
        },
        loadTrue(state){
            state.loader =true
        }
       
    },
});

export const userEnterpriseActions = userEnterpriseSlice.actions;

export default userEnterpriseSlice;