import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

class Sidebar extends Component {

  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({[menuState] : false});
    } else if(Object.keys(this.state).length === 0) {
      this.setState({[menuState] : true});
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({[i]: false});
      });
      this.setState({[menuState] : true});
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({[i]: false});
    });

  
 
  }

  render () {
 
console.log(this.props)
   
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        {!this.props.data.loader ?
        <ul className="nav">
         
        <li className={ this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item' }>
           <Link className="nav-link" to="/dashboard">
             <i className="mdi mdi-home menu-icon"></i>
             <span className="menu-title">Dashboard</span>
           </Link>
         </li>
      
      
         {this.props.data.user.roles.includes('all') ? <>
         <li className={ this.isPathActive('/company') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/company">
              <i className="mdi mdi-account-multiple-plus menu-icon"></i>
              <span className="menu-title">Company</span>
            </Link>
          </li> 
         <li className={ this.isPathActive('/hazards') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/hazards">
              <i className="mdi mdi-alert-outline menu-icon"></i>
              <span className="menu-title">Hazards</span>
            </Link>
          </li> 
       </>
      :this.props.data.user.roles.includes('admin') ? <>
      <li className={ this.isPathActive('/users') ? 'nav-item active' : 'nav-item' }>
          <Link className="nav-link" to="/users">
            <i className="mdi mdi-account menu-icon"></i>
            <span className="menu-title">User Management</span>
          </Link>
        </li>
        <li className={ this.isPathActive('/assign') ? 'nav-item active' : 'nav-item' }>
          <Link className="nav-link" to="/assign">
            <i className="menu-icon mdi mdi-floor-plan"></i>
            <span className="menu-title">Assignment</span>
          </Link>
        </li>
        <li className={ this.isPathActive('/groups') ? 'nav-item active' : 'nav-item' }>
          <Link className="nav-link" to="/groups">
            <i className="mdi mdi-account-multiple menu-icon"></i>
            <span className="menu-title">Groups</span>
          </Link>
        </li>
        <li className={ this.isPathActive('/activity') ? 'nav-item active' : 'nav-item' }>
          <Link className="nav-link" to="/activity">
            <i className="mdi mdi-led-on menu-icon"></i>
            <span className="menu-title">Work Activity</span>
          </Link>
        </li>
      </>:this.props.data.user.roles.includes('user') && this.props.data.user.applicationRoles.includes('team') ?<>
       <li className={ this.isPathActive('/activity') ? 'nav-item active' : 'nav-item' }>
       <Link className="nav-link" to="/activity">
         <i className="mdi mdi-account-multiple menu-icon"></i>
         <span className="menu-title">Toolbox</span>
       </Link>
     </li>
     </>
      :''}
       
          
          {/* <li className={ this.isPathActive('/vessels') ? 'nav-item active' : 'nav-item' }>
          <Link className="nav-link" to="/vessels">
            <i className="mdi mdi-priority-high  menu-icon"></i>
            <span className="menu-title">Vessels</span>
          </Link>
        </li> */}
        {/* <li className={ this.isPathActive('/permit') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/permit">
              <i className="mdi mdi-playlist-check menu-icon"></i>
              <span className="menu-title">Permit Library</span>
            </Link>
          </li> <li className={ this.isPathActive('/documents') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/documents">
              <i className="mdi mdi-file-document menu-icon"></i>
              <span className="menu-title">Document Library</span>
            </Link>
          </li> */}
          
         
     
     
          {/* <li className={ this.isPathActive('/asset') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/asset">
              <i className="mdi mdi-wrench  menu-icon"></i>
              <span className="menu-title">Asset Management</span>
            </Link>
          </li>

          <li className={ this.isPathActive('/move') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/move">
              <i className="mdi mdi-priority-high  menu-icon"></i>
              <span className="menu-title">Relocate Assets</span>
            </Link>
          </li> */}
         

         
         
          

          

        </ul>:''}
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}
const mapStateToProps=state=>{
  return{
    data:state.details
  }

}
export default connect(mapStateToProps) (withRouter(Sidebar));