// export const API_URL = 'http://localhost:3031';
export const API_URL ='https://risk-api.acuizen.com';


export const LOGIN_URL = API_URL + '/users/login';
export const USER_MEL = API_URL + '/users/me';
export const LOCATIONS_URL = API_URL + '/locations';
export const TIER1_URL = API_URL + '/tier-ones';
export const MEETING_URL = API_URL + '/meetings';
export const ACTIVITIES_URL = API_URL + '/meetings';
export const TODO_URL = API_URL + '/todo-api';
export const USERS_OWNER_URL = API_URL + '/users';
export const USERS_CREW_URL = API_URL + '/user-crew';
export const USERS_SIGNUP_CREW_URL = API_URL + '/users/signup-crew';
export const VESSEL_URL = API_URL + '/vessels';
export const USER_REGISTER_URL = API_URL + '/users/signup';
export const ASSIGNED_URL = API_URL + '/assigned-api';
export const SUBMIT_URL = API_URL + '/submit-api';
export const CHECKLIST_URL = API_URL + '/checklists';
export const DOCUMENTS_URL = API_URL + '/documents';
export const USER_MEETING_URL = API_URL + '/meetings'
export const RISK_UPDATES = API_URL + '/risk-updates'
export const USER_RISK_URL = API_URL + '/risk-assessment-version-twos'
export const FILE_URL = API_URL + '/files';
export const TEST_CASE_URL = API_URL + '/test-cases';
export const NEW_TEST_CASE_URL = API_URL + '/new-test-cases';
export const MOVE_URL = API_URL + '/movement';
export const TITTLE_CONFIG = API_URL + '/title-configs'
export const HAZARDS_CATEGOTY = API_URL + '/hazards-categories'
export const HAZARDS = API_URL + '/hazards'

export const COMPANY_LIST =API_URL +'/enterprise/list'
export const COMPANY_ADD =API_URL +'/enterprise/add'
export const ASSIGN_USER_GROUP = API_URL +'/group-users-add';
export const GROUP_USER_RA_ASSIGN =API_URL +'/group-assign-user';
export const ENTERPRISE_TITLE_URL=(id)=>{
    return API_URL + '/enterprises/' + id +'/title-config';
}
export const  ENTERPRISE_CHECKLIST_URL =(id)=>{
    return API_URL + '/enterprises/' + id +'/checklists';
}
export const  ENTERPRISE_DEPARTMENT_URL =(id)=>{
    return API_URL + '/enterprises/' + id +'/departments';
}
export const  GROUP_USERS_WITH_ID =(id)=>{
    return API_URL + '/group-user-group/' + id ;
}
export const  ENTERPRISE_ID_URL =(id)=>{
    return API_URL + '/enterprises/' + id ;
}
export const  ENTERPRISE_UPDATE_ID_URL =(id,id1)=>{
    return API_URL + '/enterprise_update/' + id+'/'+id1 ;
}
// export const ASSIGN_USER_GROUP =(id)=>{
//  return API_URL +'/group_users/'+id;
// }
export const GROUP_WITH_ID_URL = (id) => {
    return API_URL + '/groups/' + id;
}
export const  CHANGE_PASSWORD_ID_URL =(id)=>{
    return API_URL + '/changepassword/' + id ;
}
export const ENTERPRISE_GROUP_URL = (id) => {
    return API_URL + '/enterprises/' + id +'/groups';
}
export const  ENTERPRISE_DOCUMENT_URL =(id)=>{
    return API_URL + '/enterprises/' + id +'/documents';
}
export const  ENTERPRISE_USER_RISK_URL =(id)=>{
    return API_URL + '/enterprises/' + id +'/risk-assessment-version-twos';
}
export const  USER_RISK_URL_ID =(id)=>{
    return API_URL + '/users/' + id +'/risk-assessment-version-twos';
}
export const  GROUP_USER_ASSIGN =(id)=>{
    return API_URL + '/group-assign-user/' + id ;
}
export const  ENTERPRISE_USER_OWNER_URL =(id)=>{
    return API_URL + '/enterprises/' + id +'/users_owner';
}
export const USER_ENTERPRISE_URL = (id) => {
    return API_URL + '/users/' + id +'/enterprises';
}
export const ENTERPRISE_USER_URL = (id) => {
    return API_URL + '/enterprises/' + id +'/users';
}
// export const NESTED_SUBTOPIC_URL = '/sub-topics';

// export const STANDARDS_URL = API_URL + '/standards';

// export const QUESTIONS_URL = API_URL + '/questions';
// export const SUBQUESTIONS_URL = API_URL + '/sub-questions';
// export const NESTED_SUBQUESTIONS_URL = '/sub-questions';

// export const SURVEYS_URL = API_URL + '/surveys';

// export const STAKEHOLDERS_URL = API_URL + '/stake-holders';
export const DOCUMENTS_WITH_ID_URL = (id) => {
    return API_URL + '/documents/' + id;
}
export const HAZARDS_WITH_ID_URL = (id) => {
    return API_URL + '/hazards/' + id;
}
export const USER_WITH_ID_URL = (id) => {
    return API_URL + '/users/' + id;
}

export const SUBMIT_WITH_ID_URL = (id) => {
    return API_URL + '/submit-api/' + id;
}

export const TEST_CASE_WITH_ID_URL = (id) => {
    return API_URL + '/test-cases/' + id;
}


export const NEW_TEST_CASE_WITH_ID_URL = (id) => {
    return API_URL + '/new-test-cases/' + id;
}
export const RISK_UPDATE_WITH_ID_URL = (id) => {
    return API_URL + '/risk-assessment-version-twos/' + id + '/risk-updates';
}
export const HAZARD_WITH_ID_URL = (id) => {
    return API_URL + '/hazards-categories/' + id + '/hazards';
}
export const HAZARD_CATEGOTY_WITH_ID_URL = (id) => {
    return API_URL + '/hazards-categories/' + id;
}
export const CHECKLIST_WITH_ID_URL = (id) => {
    return API_URL + '/checklists/' + id;
}
export const VESSEL_WITH_ID_URL = (id) => {
    return API_URL + '/vessels/' + id;
}
export const MEETING_WITH_ID_URL = (id) => {
    return API_URL + '/meetings/' + id;
}
export const RISK_WITH_ID_URL = (id) => {
    return API_URL + '/risk-assessment-version-twos/' + id;
}
export const CREW_WITH_ID_URL = (id) => {
    return API_URL + '/user-crew/' + id;
}

export const LOCATION_WITH_ID_URL = (id) => {
    return API_URL + '/locations/' + id;
}

export const ASSINGED_TEST_CASE_URL = (id) => {
    return API_URL + '/assign-test-cases/' + id;
}


export const DOWNLOAD_DOCS_URL = (files) => {
    return API_URL + '/docs/' + files;
}

export const LOCATION_CONFIG_URL = (id) => {
    return API_URL + '/locations/' + id + '/config';
}
export const VESSELS_MEETING_URL = (id) => {
    return API_URL + '/vessels/' + id + '/meetings';
}
export const VESSELS_RISK_URL = (id) => {
    return API_URL + '/vessels/' + id + '/risk-assessment-version-twos';
}
export const VESSELS_USERS_URL = (id) => {
    return API_URL + '/vessels/' + id + '/user';
}

export const LOCATION_TIER1_URL = (id) => {
    return API_URL + '/locations/' + id + '/top-level-components';
}
export const DEPARTMENT_ACTIVITY_URL = (id) => {
    return API_URL + '/departments/' + id + '/work-activities';
}

export const TIER1_TIER2_URL = (id) => {
    return API_URL + '/tier-ones/' + id + '/tier-twos';
}

export const TIER2_TIER3_URL = (id) => {
    return API_URL + '/tier-twos/' + id + '/tier-threes';
}

export const TIER3_TIER4_URL = (id) => {
    return API_URL + '/tier-twos/' + id + '/tier-threes';
}

export const TIER4_TIER5_URL = (id) => {
    return API_URL + '/tier-threes/' + id + '/tier-fours';
}


export const EDIT_TIER_URL = (mode, id) => {
    switch (mode) {
        case 'tier1':

            return API_URL + '/departments/' + id;

        case 'tier2':

            return API_URL + '/work-activities/' + id;

        case 'tier3':

            return API_URL + '/tier-twos/' + id;

        case 'tier4':

            return API_URL + '/tier-threes/' + id;

        default:
            break;
    }
}