import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

const {SearchBar} =Search


const SagoTable = (props) => {

    const CustomSizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <div>
            <span className="font-12">Results per page:</span>
            <select className="ml-2 rows-dropdown" value={currSizePerPage} onChange={e => onSizePerPageChange(e.target.value)}>
                {
                    options.map((option, idx) => {
                        return <option key={idx} value={option.page}>{option.text}</option>;
                    })
                }
            </select>
        </div>
    );


    const customTotal = (from, to, size) => (
        <span className="font-12 react-bootstrap-table-pagination-total">
            {size} Results Found
        </span>
    );

    const customPagination = paginationFactory({
        alwaysShowAllBtns: true,
        withFirstAndLast: false,
        paginationTotalRenderer: customTotal,
        showTotal: true,
        sizePerPageRenderer: CustomSizePerPageRenderer,
        nextPageText: (<i className="mdi mdi-chevron-right"></i>),
        prePageText: (<i className="mdi mdi-chevron-left"></i>)
    })

    return (
        <>
            {<ToolkitProvider
                keyField="id"
                bootstrap4
                data={props.data}
                columns={props.columns}
                search
            >
                {
                    props => (
                        <div className="w-100">
                            <div className="d-flex align-items-center justify-content-end">
                                <p className="mb-2 mr-2">Search in table:</p>
                                <SearchBar {...props.searchProps} />
                            </div>
                            <BootstrapTable classes="table-responsive table-striped table-borderless bg-white"

                                pagination={customPagination}

                                {...props.baseProps}
                                wrapperClasses="table-responsive table-striped table-borderless bg-white"
                            />
                        </div>
                    )
                }
            </ToolkitProvider>}
        </>
    )
}

export default SagoTable;